import { UserDisplayName } from '../@types/user';

const userDisplayName = function (user: UserDisplayName) {
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }
  return user.email;
};

export { userDisplayName };
