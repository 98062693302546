import { ReactNode } from 'react';
import { Alert, AlertTitle, Container } from '@mui/material';
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  accessibleRoles: string[];
  children: ReactNode | string;
};

const useCurrentRoles = (userData: Record<string, any> | null) => userData?.roles || [];
export default function RoleBasedGuard({ accessibleRoles, children }: RoleBasedGuardProp) {
  const { user } = useAuth();
  const currentRoles = useCurrentRoles(user);

  if (!accessibleRoles.some((r) => currentRoles.includes(r))) {
    return (
      <Container>
        <Alert severity="error" sx={{ mt: 5 }}>
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
