import { gql } from '@apollo/client/core';

export const UPDATE_USER_PROFILE = gql`
  mutation updateUserProfile($cognitoId: ID!, $input: UserProfileInput) {
    updateUserProfile(cognitoId: $cognitoId, input: $input) {
      cognitoId
      salutation
      firstName
      secondName
      lastName
      jobTitle
      profileImage
      phone
      companyInfo
      personalInfo
      postCards
      cookieAccept
      personalityToken
      personalityEnable
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($cognitoId: ID!, $input: UserInput) {
    updateUser(cognitoId: $cognitoId, input: $input) {
      cognitoId
      coachTypes
    }
  }
`;

export const CREATE_CONVERSATION = gql`
  mutation createConversation($name: String!) {
    createConversation(name: $name) {
      id
    }
  }
`;

export const CREATE_CONVERSATION_USER = gql`
  mutation createConversationUser($conversationId: ID!, $cognitoId: ID!) {
    createConversationUser(conversationId: $conversationId, cognitoId: $cognitoId) {
      conversationId
    }
  }
`;

export const CREATE_MESSAGE = gql`
  mutation createMessage(
    $conversationId: ID!
    $senderCognitoId: ID!
    $content: String!
    $file: FileInput
    $id: ID
    $body: String
    $contentType: String
    $attachments: [String]
    $createdAt: String
    $senderId: ID
  ) {
    createMessage(
      conversationId: $conversationId
      senderCognitoId: $senderCognitoId
      content: $content
      file: $file
      id: $id
      body: $body
      contentType: $contentType
      attachments: $attachments
      createdAt: $createdAt
      senderId: $senderId
    ) {
      id
      content
      file {
        name
        url
      }
      createdAt
      senderCognitoId
      conversationId
      body
      contentType
      attachments
      senderId
    }
  }
`;

export const CREATE_NOTIFICATION = gql`
  mutation createNotification(
    $parentId: ID
    $senderCognitoId: ID!
    $receiverCognitoId: ID!
    $type: String!
    $status: String!
    $conversationId: ID!
    $message: String
  ) {
    createNotification(
      parentId: $parentId
      senderCognitoId: $senderCognitoId
      receiverCognitoId: $receiverCognitoId
      type: $type
      status: $status
      conversationId: $conversationId
      message: $message
    ) {
      id
      parentId
      senderCognitoId
      sender {
        cognitoId
        email
        firstName
        lastName
        profileImage
      }
      receiverCognitoId
      receiver {
        cognitoId
        email
        firstName
        lastName
        profileImage
      }
      type
      status
      conversationId
      message
    }
  }
`;

export const UPDATE_NOTIFICATION_HISTORY = gql`
  mutation updateNotificationHistory($id: ID!, $input: NotificationHistoryInput) {
    updateNotificationHistory(id: $id, input: $input) {
      createdAt
      id
      read
      recipientCognitoId
      senderCognitoId
      type
      updatedAt
    }
  }
`;

export const CREATE_PERSONAL_NOTE = gql`
  mutation createPersonalNote(
    $cognitoId: ID!
    $targetCognitoId: ID!
    $content: String!
    $title: String!
  ) {
    createPersonalNote(
      cognitoId: $cognitoId
      targetCognitoId: $targetCognitoId
      content: $content
      title: $title
    ) {
      id
      title
      content
      createdAt
    }
  }
`;

export const UPDATE_PERSONAL_NOTE = gql`
  mutation updatePersonalNote($id: ID!, $createdAt: String!, $content: String!, $title: String!) {
    updatePersonalNote(id: $id, createdAt: $createdAt, content: $content, title: $title) {
      id
      title
      content
      createdAt
    }
  }
`;

export const DELETE_PERSONAL_NOTE = gql`
  mutation deletePersonalNote($id: ID!, $createdAt: String!) {
    deletePersonalNote(id: $id, createdAt: $createdAt) {
      id
    }
  }
`;

export const CREATE_PERSONAL_GOAL_CATEGORY = gql`
  mutation createPersonalGoalCategory(
    $cognitoId: ID!
    $targetCognitoId: ID!
    $title: String!
    $description: String
  ) {
    createPersonalGoalCategory(
      cognitoId: $cognitoId
      targetCognitoId: $targetCognitoId
      title: $title
      description: $description
    ) {
      id
    }
  }
`;

export const UPDATE_PERSONAL_GOAL_CATEGORY = gql`
  mutation updatePersonalGoalCategory($id: ID!, $title: String!, $description: String) {
    updatePersonalGoalCategory(id: $id, title: $title, description: $description) {
      id
    }
  }
`;

export const DELETE_PERSONAL_GOAL_CATEGORY = gql`
  mutation deletePersonalGoalCategory($id: ID!) {
    deletePersonalGoalCategory(id: $id) {
      id
    }
  }
`;

export const CREATE_PERSONAL_GOAL = gql`
  mutation createPersonalGoal(
    $cognitoId: ID!
    $targetCognitoId: ID!
    $categoryId: ID!
    $input: PersonalGoalInput
  ) {
    createPersonalGoal(
      cognitoId: $cognitoId
      targetCognitoId: $targetCognitoId
      categoryId: $categoryId
      input: $input
    ) {
      id
      title
      milestoneType
      eventStartAt
      eventEndAt
      officialCertificate
      passed
      description
    }
  }
`;

export const UPDATE_PERSONAL_GOAL = gql`
  mutation updatePersonalGoal($id: ID!, $eventStartAt: String!, $input: PersonalGoalInput) {
    updatePersonalGoal(id: $id, eventStartAt: $eventStartAt, input: $input) {
      id
      title
      milestoneType
      eventStartAt
      eventEndAt
      officialCertificate
      passed
      description
    }
  }
`;
export const RE_UPDATE_PERSONAL_GOAL = gql`
  mutation reUpdatePersonalGoal($id: ID!, $eventStartAt: String!, $input: PersonalGoalInput) {
    reUpdatePersonalGoal(id: $id, eventStartAt: $eventStartAt, input: $input) {
      id
      title
      milestoneType
      eventStartAt
      eventEndAt
      officialCertificate
      passed
      description
    }
  }
`;
export const UPDATE_PERSONAL_SETTINGS = gql`
  mutation updatePersonalSettings($cognitoId: ID!, $targetCognitoId: ID!, $goalFlagName: String!) {
    updatePersonalSettings(
      cognitoId: $cognitoId
      targetCognitoId: $targetCognitoId
      goalFlagName: $goalFlagName
    ) {
      goalFlagName
    }
  }
`;

export const DELETE_PERSONAL_GOAL = gql`
  mutation deletePersonalGoal($id: ID!, $eventStartAt: String!) {
    deletePersonalGoal(id: $id, eventStartAt: $eventStartAt) {
      id
    }
  }
`;

export const CREATE_TOPIC = gql`
  mutation createTopic(
    $description: String!
    $title: String!
    $topicCategoryId: String!
    $cognitoId: ID!
  ) {
    createTopic(
      description: $description
      title: $title
      topicCategoryId: $topicCategoryId
      cognitoId: $cognitoId
    ) {
      createdAt
      description
      id
      title
      updatedAt
      cognitoId
    }
  }
`;

export const CREATE_TOPIC_COMMENT = gql`
  mutation createTopicComment(
    $topicId: ID!
    $parentCommentId: ID
    $content: String!
    $cognitoId: ID!
  ) {
    createTopicComment(
      topicId: $topicId
      parentCommentId: $parentCommentId
      content: $content
      cognitoId: $cognitoId
    ) {
      cognitoId
      content
      createdAt
      id
      parentCommentId
      topicId
      updatedAt
    }
  }
`;

export const CREATE_TOPIC_COMMENT_VOTE = gql`
  mutation createTopicComment($topicCommentId: ID!, $cognitoId: ID!, $isLike: Boolean!) {
    createTopicCommentVote(
      topicCommentId: $topicCommentId
      cognitoId: $cognitoId
      isLike: $isLike
    ) {
      id
      topicCommentId
      cognitoId
      isLike
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_TOPIC_COMMENT_VOTE = gql`
  mutation deleteTopicComment($topicCommentVoteId: ID!) {
    deleteTopicCommentVote(topicCommentVoteId: $topicCommentVoteId) {
      id
      topicCommentId
      cognitoId
      isLike
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_TOPIC_CATEGORY = gql`
  mutation createTopicCategory($title: String!) {
    createTopicCategory(title: $title) {
      id
      title
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_TOPIC_CATEGORY = gql`
  mutation updateTopicCategory($id: ID!, $title: String!) {
    updateTopicCategory(id: $id, title: $title) {
      id
      title
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_TOPIC_CATEGORY = gql`
  mutation deleteTopicCategory($id: ID!) {
    deleteTopicCategory(id: $id)
  }
`;

export const CREATE_GROUP = gql`
  mutation createGroup($name: String!) {
    createGroup(name: $name) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation updateGroup($id: ID!, $name: String!) {
    updateGroup(id: $id, name: $name) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_GROUP = gql`
  mutation deleteGroup($id: ID!) {
    deleteGroup(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_GROUP_USER = gql`
  mutation createGroupUser($groupId: ID!, $cognitoId: ID!) {
    createGroupUser(groupId: $groupId, cognitoId: $cognitoId) {
      cognitoId
    }
  }
`;

export const REMOVE_GROUP_USER = gql`
  mutation removeGroupUser($groupId: ID!, $cognitoId: ID!) {
    removeGroupUser(groupId: $groupId, cognitoId: $cognitoId) {
      user {
        firstName
        email
      }
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_PROFILE_CONFIG = gql`
  mutation updateProfileConfig($type: String!, $value: AWSJSON!) {
    updateProfileConfig(type: $type, value: $value) {
      type
      value
    }
  }
`;

export const UPDATE_DOCUMENT_CATEGORY = gql`
  mutation updateDocumentCategory($category: AWSJSON!) {
    updateDocumentCategory(category: $category) {
      category
    }
  }
`;

export const CREATE_DOCUMENT = gql`
  mutation createDocument($cognitoId: ID!, $input: DocumentInput) {
    createDocument(cognitoId: $cognitoId, input: $input) {
      pk
      sk
      categoryId
      cognitoId
      title
      published
      type
      file
      tags
      comment
      description
      answer
      createdAt
      updatedAt
      user {
        email
        profileImage
        firstName
        lastName
        jobTitle
        companyInfo
        cognitoId
      }
    }
  }
`;

export const UPDATE_DOCUMENT = gql`
  mutation updateDocument($pk: String!, $sk: String!, $input: DocumentInput) {
    updateDocument(pk: $pk, sk: $sk, input: $input) {
      pk
      sk
      categoryId
      cognitoId
      title
      published
      type
      file
      tags
      comment
      description
      answer
      createdAt
      updatedAt
      user {
        email
        profileImage
        firstName
        lastName
        jobTitle
        companyInfo
        cognitoId
      }
    }
  }
`;

export const CREATE_SELF_NOTE = gql`
  mutation createSelfNote($cognitoId: ID!, $content: String!, $title: String!) {
    createSelfNote(cognitoId: $cognitoId, content: $content, title: $title) {
      id
    }
  }
`;

export const DELETE_SELF_NOTE = gql`
  mutation deleteSelfNote($id: ID!) {
    deleteSelfNote(id: $id) {
      id
    }
  }
`;

export const UPDATE_SELF_NOTE = gql`
  mutation updateSelfNote($id: ID!, $content: String!, $title: String!) {
    updateSelfNote(id: $id, content: $content, title: $title) {
      id
    }
  }
`;

export const CREATE_QUESTIONNAIRE = gql`
  mutation createQuestionnaire($title: String!, $description: String) {
    createQuestionnaire(title: $title, description: $description) {
      id
      title
      description
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_QUESTIONNAIRE_QUESTION = gql`
  mutation createQuestionnaireQuestion(
    $questionnaireId: ID!
    $question: String!
    $createdAt: String!
  ) {
    createQuestionnaireQuestion(
      questionnaireId: $questionnaireId
      question: $question
      createdAt: $createdAt
    ) {
      id
      question
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_QUESTIONNAIRE_USER = gql`
  mutation createQuestionnaireUser($questionnaireId: ID!, $cognitoId: ID!) {
    createQuestionnaireUser(questionnaireId: $questionnaireId, cognitoId: $cognitoId) {
      cognitoId
      user {
        email
      }
    }
  }
`;

export const CREATE_QUESTIONNAIRE_ANSWER = gql`
  mutation createQuestionnaireAnswer(
    $questionnaireId: ID!
    $questionnaireQuestionId: ID!
    $cognitoId: ID!
    $answer: String!
  ) {
    createQuestionnaireAnswer(
      questionnaireId: $questionnaireId
      questionnaireQuestionId: $questionnaireQuestionId
      cognitoId: $cognitoId
      answer: $answer
    ) {
      answer
      cognitoId
      createdAt
      id
    }
  }
`;

export const UPDATE_QUESTIONNAIRE_ANSWER = gql`
  mutation updateQuestionnaireAnswer($id: ID!, $answer: String!) {
    updateQuestionnaireAnswer(id: $id, answer: $answer) {
      id
    }
  }
`;

export const UPDATE_QUESTIONNAIRE = gql`
  mutation updateQuestionnaire($id: ID!, $title: String!, $description: String) {
    updateQuestionnaire(id: $id, title: $title, description: $description) {
      id
      title
      description
      questions {
        id
        question
        createdAt
      }
      users {
        cognitoId
        user {
          email
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_QUESTIONNAIRE_QUESTION = gql`
  mutation updateQuestionnaireQuestion($id: ID!, $question: String!) {
    updateQuestionnaireQuestion(id: $id, question: $question) {
      id
      question
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_QUESTIONNAIRE_USER = gql`
  mutation deleteQuestionnaireUser($questionnaireId: ID!, $cognitoId: ID!) {
    deleteQuestionnaireUser(questionnaireId: $questionnaireId, cognitoId: $cognitoId) {
      cognitoId
    }
  }
`;

export const DELETE_QUESTIONNAIRE = gql`
  mutation deleteQuestionnaire($id: ID!) {
    deleteQuestionnaire(id: $id) {
      id
    }
  }
`;

export const DELETE_QUESTIONNAIRE_QUESTION = gql`
  mutation deleteQuestionnaireQuestion($id: ID!) {
    deleteQuestionnaireQuestion(id: $id) {
      id
    }
  }
`;

export const UPDATE_ADMIN_CONFIG = gql`
  mutation updateAdminConfig($key: String!, $value: String!) {
    updateAdminConfig(key: $key, value: $value) {
      key
    }
  }
`;

export const CREATE_BLOG_POST = gql`
  mutation createBlogPost(
    $cognitoId: ID!
    $sectionId: ID!
    $title: String!
    $image: String!
    $tags: AWSJSON
    $content: String!
  ) {
    createBlogPost(
      cognitoId: $cognitoId
      sectionId: $sectionId
      title: $title
      image: $image
      tags: $tags
      content: $content
    ) {
      id
    }
  }
`;

export const UPDATE_BLOG_POST_VIEWS = gql`
  mutation updateBlogPostViews($id: ID!, $views: String) {
    updateBlogPostViews(id: $id, views: $views) {
      id
      views
    }
  }
`;

export const UPDATE_BLOG_POST = gql`
  mutation updateBlogPost(
    $id: ID!
    $sectionId: ID
    $title: String
    $image: String
    $tags: AWSJSON
    $content: String
    $isPublished: Boolean
  ) {
    updateBlogPost(
      id: $id
      sectionId: $sectionId
      title: $title
      image: $image
      tags: $tags
      content: $content
      isPublished: $isPublished
    ) {
      id
    }
  }
`;

export const DELETE_BLOG_POST = gql`
  mutation deleteBlogPost($id: ID!) {
    deleteBlogPost(id: $id) {
      id
    }
  }
`;

export const UPDATE_USER_ALLOW_BLOG_POST = gql`
  mutation updateUserAllowBlogPost($cognitoId: ID!, $allowBlogPost: Boolean!) {
    updateUserAllowBlogPost(cognitoId: $cognitoId, allowBlogPost: $allowBlogPost) {
      cognitoId
    }
  }
`;

export const CREATE_BLOG_SECTION = gql`
  mutation createBlogSection($name: String!, $color: String!) {
    createBlogSection(name: $name, color: $color) {
      id
    }
  }
`;

export const UPDATE_BLOG_SECTION = gql`
  mutation updateBlogSection($id: ID!, $name: String!, $color: String!) {
    updateBlogSection(id: $id, name: $name, color: $color) {
      id
    }
  }
`;

export const DELETE_BLOG_SECTION = gql`
  mutation deleteBlogSection($id: ID!) {
    deleteBlogSection(id: $id)
  }
`;

export const CREATE_BLOG_POST_LIKE = gql`
  mutation createBlogPostLike($cognitoId: ID!, $postId: ID!) {
    createBlogPostLike(cognitoId: $cognitoId, postId: $postId) {
      postId
    }
  }
`;

export const DELETE_BLOG_POST_LIKE = gql`
  mutation deleteBlogPostLike($cognitoId: ID!, $postId: ID!) {
    deleteBlogPostLike(cognitoId: $cognitoId, postId: $postId) {
      postId
    }
  }
`;

export const CREATE_RELATION = gql`
  mutation createRelation($targetCognitoId: ID!, $cognitoId: ID!) {
    createRelation(targetCognitoId: $targetCognitoId, cognitoId: $cognitoId) {
      cognitoId
    }
  }
`;

export const DELETE_RELATION = gql`
  mutation deleteRelation($targetCognitoId: ID!, $cognitoId: ID!) {
    deleteRelation(targetCognitoId: $targetCognitoId, cognitoId: $cognitoId) {
      cognitoId
    }
  }
`;

export const CREATE_VISION_DOCUMENT = gql`
  mutation createVision(
    $cognitoId: ID!
    $targetCognitoId: ID
    $title: String
    $category: AWSJSON
    $status: Boolean
    $publishedAt: String
    $description: String
  ) {
    createVision(
      cognitoId: $cognitoId
      targetCognitoId: $targetCognitoId
      title: $title
      category: $category
      status: $status
      publishedAt: $publishedAt
      description: $description
    ) {
      id
    }
  }
`;

export const UPDATE_VISION_DOCUMENT = gql`
  mutation updateVision(
    $id: ID!
    $targetCognitoId: ID
    $title: String
    $category: AWSJSON
    $status: Boolean
    $publishedAt: String
    $description: String
  ) {
    updateVision(
      id: $id
      targetCognitoId: $targetCognitoId
      title: $title
      category: $category
      status: $status
      publishedAt: $publishedAt
      description: $description
    ) {
      id
      publishedAt
      title
      category
      status
    }
  }
`;

export const DELETE_VISION_DOCUMENT = gql`
  mutation deleteVision($id: ID!) {
    deleteVision(id: $id) {
      id
    }
  }
`;

export const CREATE_EVENT = gql`
  mutation createEvent(
    $cognitoId: ID!
    $allDay: Boolean
    $description: String
    $endDate: String
    $startDate: String
    $targetCognitoId: ID
    $textColor: String
    $title: String
  ) {
    createEvent(
      cognitoId: $cognitoId
      allDay: $allDay
      description: $description
      endDate: $endDate
      startDate: $startDate
      targetCognitoId: $targetCognitoId
      textColor: $textColor
      title: $title
    ) {
      allDay
      cognitoId
      user {
        cognitoId
        email
        firstName
        lastName
        jobTitle
        profileImage
        companyInfo
      }
      createdAt
      description
      endDate
      id
      startDate
      targetCognitoId
      targetUser {
        cognitoId
        email
        firstName
        lastName
        jobTitle
        profileImage
        companyInfo
      }
      textColor
      title
      statusEvent
      updatedAt
    }
  }
`;

export const CREATE_NOTIFICATION_HISTORY = gql`
  mutation createNotificationHistory(
    $recipientCognitoId: ID!
    $senderCognitoId: ID!
    $type: NotificationType
    $eventId: ID
  ) {
    createNotificationHistory(
      recipientCognitoId: $recipientCognitoId
      senderCognitoId: $senderCognitoId
      type: $type
      eventId: $eventId
    ) {
      createdAt
      id
      type
      updatedAt
      recipient {
        firstName
        email
        jobTitle
        lastName
        profileImage
        cognitoId
      }
      sender {
        email
        firstName
        jobTitle
        lastName
        profileImage
        cognitoId
      }
      event {
        allDay
        cognitoId
        user {
          cognitoId
          email
          firstName
          lastName
          jobTitle
          profileImage
          companyInfo
        }
        createdAt
        description
        endDate
        id
        startDate
        targetCognitoId
        targetUser {
          cognitoId
          email
          firstName
          lastName
          jobTitle
          profileImage
          companyInfo
        }
        textColor
        title
        statusEvent
        updatedAt
      }
    }
  }
`;

export const UPDATE_EVENT = gql`
  mutation updateEvent(
    $cognitoId: ID!
    $id: ID!
    $allDay: Boolean
    $description: String
    $endDate: String
    $startDate: String
    $targetCognitoId: ID
    $textColor: String
    $title: String
    $statusEvent: String
  ) {
    updateEvent(
      cognitoId: $cognitoId
      id: $id
      allDay: $allDay
      description: $description
      endDate: $endDate
      startDate: $startDate
      targetCognitoId: $targetCognitoId
      textColor: $textColor
      title: $title
      statusEvent: $statusEvent
    ) {
      allDay
      cognitoId
      user {
        cognitoId
        email
        firstName
        lastName
        jobTitle
        profileImage
        companyInfo
      }
      createdAt
      description
      endDate
      id
      startDate
      targetCognitoId
      targetUser {
        cognitoId
        email
        firstName
        lastName
        jobTitle
        profileImage
        companyInfo
      }
      textColor
      title
      statusEvent
      updatedAt
    }
  }
`;

export const DELETE_EVENT = gql`
  mutation deleteEvent($id: ID!) {
    deleteEvent(id: $id) {
      id
    }
  }
`;

export const CREATE_QUESTION = gql`
  mutation createQuestion($cognitoId: ID!, $input: QuestionInput) {
    createQuestion(cognitoId: $cognitoId, input: $input) {
      pk
      sk
      categoryId
      cognitoId
      targetCognitoId
      title
      description
      answer
      status
      published
      type
      file
      tags
      createdAt
      updatedAt
      user {
        email
        profileImage
        firstName
        lastName
        jobTitle
        companyInfo
        cognitoId
      }
      targetUser {
        email
        profileImage
        firstName
        lastName
        jobTitle
        companyInfo
        cognitoId
      }
    }
  }
`;
export const UPDATE_QUESTION = gql`
  mutation updateQuestion($pk: String!, $sk: String!, $input: QuestionInput) {
    updateQuestion(pk: $pk, sk: $sk, input: $input) {
      pk
      sk
      categoryId
      cognitoId
      targetCognitoId
      title
      description
      answer
      status
      published
      type
      file
      tags
      createdAt
      updatedAt
      user {
        email
        profileImage
        firstName
        lastName
        jobTitle
        companyInfo
        cognitoId
      }
      targetUser {
        email
        profileImage
        firstName
        lastName
        jobTitle
        companyInfo
        cognitoId
      }
    }
  }
`;

export const UPDATE_JITSI_SETTING = gql`
  mutation updateJitsiSetting($input: JitsiSettingInput!) {
    updateJitsiSetting(input: $input) {
      id
      key
      value
    }
  }
`;
