import React, { useState } from 'react';
// @mui
import { Button, Dialog, DialogActions, DialogTitle, Stack, Typography } from '@mui/material';
// hooks
import useNotification from '../../hooks/useNotification';
// components
import Avatar from '../../components/Avatar';
// utils
import getUserAvatar from '../../utils/getUserAvatar';
import { useSelector } from '../../redux/store';

export default function IncomingCall() {
  const { acceptCallJitsi, handleCloseJitsiCalling } = useNotification();
  const [showIncoming, setShowIncoming] = useState(true);

  const { caller, receiver } = useSelector((state) => state.call);
  const handleAccept = () => {
    acceptCallJitsi();
    setShowIncoming(false);
  };

  const onCloseCalling = () => {
    handleCloseJitsiCalling(receiver?.cognitoId, caller?.cognitoId);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        open={showIncoming}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Stack sx={{ display: 'flex' }}>
            <Avatar
              src={getUserAvatar({
                cognitoId: caller?.cognitoId,
                profileImage: caller?.profileImage,
              })}
              sx={{
                zIndex: 9,
                // top: 24,
                // left: 24,
                // width: 40,
                // height: 40,
                // position: 'absolute',
              }}
            />
            <Typography>{`${caller?.firstName || caller?.email} is calling...`}</Typography>
          </Stack>
        </DialogTitle>
        <DialogActions>
          <Button size="small" variant="contained" color="error" onClick={() => onCloseCalling()}>
            Decline
          </Button>
          <Button size="small" variant="contained" color="success" onClick={handleAccept} autoFocus>
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
