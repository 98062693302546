import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { AUTH_TYPE, AuthOptions, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
// Config
import { AWS_APPSYNC, COGNITO_API } from '../config';
import { CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';

// Get token from user
let token = '';

const UserPool = new CognitoUserPool({
  UserPoolId: COGNITO_API.userPoolId || '',
  ClientId: COGNITO_API.clientId || '',
});

const user = UserPool.getCurrentUser();

if (user) {
  user.getSession((err: Error | null, session: CognitoUserSession | null) => {
    if (!err) {
      token = session?.getIdToken().getJwtToken() || '';
    }
  });
}

const { graphqlEndpoint, region } = AWS_APPSYNC;
const auth: AuthOptions = {
  type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS, //AWS_APPSYNC.authenticationType,
  jwtToken: async () => token, // TODO: Required when you use Cognito UserPools OR OpenID Connect. token object is obtained previously
  // credentials: async () => credentials, // Required when you use IAM-based auth.
};

const httpLink = createHttpLink({ uri: graphqlEndpoint });
const link = ApolloLink.from([
  createAuthLink({ url: graphqlEndpoint, region, auth }),
  createSubscriptionHandshakeLink({ url: graphqlEndpoint, region, auth }, httpLink),
]);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export const setClientLink = (token: string) => {
  const auth: AuthOptions = {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => token,
  };

  client.setLink(
    ApolloLink.from([
      createAuthLink({ url: graphqlEndpoint, region, auth }),
      createSubscriptionHandshakeLink({ url: graphqlEndpoint, region, auth }, httpLink),
    ])
  );
};
