import { gql } from '@apollo/client/core';

export const SUBSCRIPTION_NEW_MESSAGE = gql`
  subscription subscribeToNewMessage($conversationId: ID!) {
    subscribeToNewMessage(conversationId: $conversationId) {
      id
      content
      file {
        name
        url
      }
      createdAt
      senderCognitoId
      conversationId
      body
      contentType
      attachments
      senderId
    }
  }
`;

export const SUBSCRIPTION_MY_NOTIFICATION = gql`
  subscription subscribeToMyNotification($receiverCognitoId: ID!) {
    subscribeToMyNotification(receiverCognitoId: $receiverCognitoId) {
      id
      parentId
      receiver {
        cognitoId
        firstName
        lastName
        profileImage
        email
      }
      receiverCognitoId
      sender {
        cognitoId
        firstName
        jobTitle
        profileImage
        email
      }
      senderCognitoId
      status
      type
      conversationId
      message
    }
  }
`;
