import { createSlice } from '@reduxjs/toolkit';
import { filter } from 'lodash';
//
import { CallState } from '../../@types/call';
// ----------------------------------------------------------------------

const initialState: CallState = {
  receiverList: [],
  caller: '',
  receiver: '',
  isCalling: false,
  hasIncomingCall: false,
  jitsiRoomName: '',
};

const slice = createSlice({
  name: 'call',
  initialState,
  reducers: {
    // START CALLING
    startCalling(state, action) {
      state.isCalling = action.payload;
    },

    setCaller(state, action) {
      state.caller = action.payload;
    },

    setReceiver(state, action) {
      state.receiver = action.payload;
    },

    setJitsiRoomName(state, action) {
      state.jitsiRoomName = action.payload;
    },

    setIncomingCall(state, action) {
      state.hasIncomingCall = action.payload;
    },

    resetCalling(state) {
      state.isCalling = false;
      state.hasIncomingCall = false;
      state.receiverList = [];
      state.caller = '';
      state.receiver = '';
      state.jitsiRoomName = '';
    },

    setReceiverList(state, action) {
      state.receiverList = action.payload;
    },

    closeCalling(state, action) {
      const { receiverId } = action.payload;
      // Update status of receiver
      state.receiverList = state.receiverList.map((receiver) => {
        if (receiver.userId === receiverId) {
          receiver.callingStatus = 'close';
        }
        return receiver;
      });
      // Check if they decline all then we will close
      const countReciverDecline = filter(
        state.receiverList,
        (receiver) => receiver.callingStatus === 'close'
      ).length;
      if (state.receiverList.length === countReciverDecline) {
        // Need to find another way to call action reset action
        state.isCalling = false;
        state.hasIncomingCall = false;
        state.receiverList = [];
        state.caller = '';
        state.receiver = '';
        state.jitsiRoomName = '';
      }
    },

    acceptCalling(state, action) {
      const { receiverId } = action.payload;
      // Update status of receiver
      state.receiverList = state.receiverList.map((receiver) => {
        if (receiver.userId === receiverId) {
          receiver.callingStatus = 'in-call';
        }
        return receiver;
      });
    },

    // setDisconnected(state, action) {
    //   state.disconnected = action.payload;
    // },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startCalling,
  closeCalling,
  setIncomingCall,
  setCaller,
  setReceiver,
  setJitsiRoomName,
  setReceiverList,
  resetCalling,
  acceptCalling,
  // setDisconnected,
} = slice.actions;
