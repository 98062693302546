import { SettingsValueProps } from './components/settings/type';
// routes
import { PATH_DASHBOARD } from './routes/paths';

export const APP_URL = window.location.protocol + '//' + window.location.host;

// APP
export const APP_CONFIG = {
  appTitle: process.env.REACT_APP_TITLE || 'TR2',
};

// Appsync
export const AWS_APPSYNC = {
  graphqlEndpoint: process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT || '',
  region: process.env.REACT_APP_APPSYNC_REGION || 'eu-central-1',
  authenticationType: process.env.REACT_APP_APPSYNC_AUTH_TYPE || 'API_KEY',
  apiKey: process.env.REACT_APP_APPSYNC_API_KEY || '',
};

// AWS
export const AWS_S3 = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME || '',
  region: process.env.REACT_APP_S3_REGION || 'eu-central-1',
};

// TURN Server
export const TURN_SERVER = {
  ipAddress: process.env.REACT_APP_TURN_IP_ADDRESS,
  port: process.env.REACT_APP_TURN_IP_ADDRESS || 3478,
  username: process.env.REACT_APP_TURN_USERNAME,
  credential: process.env.REACT_APP_TURN_CREDENTIAL,
  url: `${process.env.REACT_APP_TURN_IP_ADDRESS}:${process.env.REACT_APP_TURN_PORT || 3478}`
}

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID || '',
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'orange',
  themeLayout: 'horizontal',
  themeStretch: false,
};
