import { createSlice } from '@reduxjs/toolkit';
import { GoalState } from '../../@types/goal';
//

// ----------------------------------------------------------------------

const initialState: GoalState = {
  dialogName: '',
  selectedGoal: null,
  selectedMilestone: null,
};

const slice = createSlice({
  name: 'goal',
  initialState,
  reducers: {
    setMilestone(state, action) {
      state.selectedMilestone = action.payload;
    },
    getGoal(state, action) {
      state.selectedGoal = action.payload;
    },
    openDialog(state, action) {
      state.dialogName = action.payload;
    },
    closeDialog(state) {
      state.dialogName = '';
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getGoal, setMilestone, openDialog, closeDialog } = slice.actions;
