import { AuthUser } from '../@types/auth';
import { AWS_S3 } from '../config';

function isValidHttpUrl(text: string) {
  let url;
  try {
    url = new URL(text);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

export default function getUserAvatar(user: AuthUser) {
  if (isValidHttpUrl(user?.picture || user?.profileImage)) {
    return user?.picture || user?.profileImage;
  }
  if (user?.picture || user?.profileImage) {
    return `https://s3.${AWS_S3.region}.amazonaws.com/${AWS_S3.bucketName}/${
      user?.sub || user?.cognitoId
    }/avatar/${user?.picture || user?.profileImage}`;
  }
  return undefined;
}
