import { gql } from '@apollo/client/core';

export const LIST_USERS = gql`
  query {
    listUsers {
      cognitoId
      email
      firstName
      lastName
      jobTitle
      profileImage
      companyInfo
      coachTypes
      onlineStatus
      allowBlogPost
      relation {
        targetCognitoId
      }
    }
  }
`;

// TODO: we had other way using Apollo to merge data to avoid duplicate code but need to test fists
export const GET_USER_WITH_CONVERSATIONS = gql`
  query getUser($cognitoId: ID!) {
    getUser(cognitoId: $cognitoId) {
      cognitoId
      email
      salutation
      firstName
      secondName
      lastName
      jobTitle
      profileImage
      phone
      companyInfo
      personalInfo
      postCards
      coachTypes
      onlineStatus
      allowBlogPost
      relation {
        targetCognitoId
      }
      conversations {
        conversationUser {
          associatedUser {
            cognitoId
          }
          conversation {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_USER_PROFILE = gql`
  query getUserProfile($cognitoId: ID!) {
    getUser(cognitoId: $cognitoId) {
      cognitoId
      email
      salutation
      firstName
      secondName
      lastName
      jobTitle
      profileImage
      phone
      companyInfo
      personalInfo
      postCards
      coachTypes
      onlineStatus
      allowBlogPost
      cookieAccept
      personalityToken
      personalityEnable
      relation {
        targetCognitoId
      }
    }
  }
`;

export const GET_CONVERSATION_MESSAGES = gql`
  query allMessage($conversationId: ID!, $first: Int, $after: String) {
    allMessage(conversationId: $conversationId, first: $first, after: $after) {
      messages {
        id
        content
        file {
          name
          url
        }
        createdAt
        senderCognitoId
        conversationId
        body
        contentType
        attachments
        senderId
      }
      nextToken
    }
  }
`;

export const GET_CONVERSATION = gql`
  query getConversation($conversationId: ID!) {
    getConversation(conversationId: $conversationId) {
      id
      name
      conversationUsers {
        conversationUser {
          cognitoId
          user {
            firstName
            lastName
            email
            profileImage
          }
        }
      }
    }
  }
`;

export const GET_PERSONAL_NOTES = gql`
  query getPersonalNotes($cognitoId: ID!, $targetCognitoId: ID!) {
    getPersonalNotes(cognitoId: $cognitoId, targetCognitoId: $targetCognitoId) {
      id
      title
      content
      createdAt
    }
  }
`;

export const LIST_TOPICS = gql`
  query {
    listTopics {
      id
      createdAt
      description
      title
      topicCategory {
        id
        title
      }
      updatedAt
      user {
        cognitoId
        profileImage
      }
      comments {
        id
        user {
          cognitoId
          profileImage
        }
      }
    }
  }
`;

export const GET_TOPIC = gql`
  query getTopic($id: ID!) {
    getTopic(id: $id) {
      createdAt
      description
      title
      id
      topicCategory {
        id
        title
      }
      updatedAt
      cognitoId
      user {
        email
        profileImage
        firstName
        lastName
        jobTitle
        companyInfo
      }
    }
  }
`;

export const GET_TOPIC_COMMENTS = gql`
  query getTopicComments($topicId: ID!) {
    getTopicComments(topicId: $topicId) {
      cognitoId
      content
      createdAt
      id
      topicId
      parentCommentId
      updatedAt
      user {
        email
        profileImage
        firstName
        lastName
        jobTitle
        companyInfo
      }
      vote {
        id
        topicCommentId
        cognitoId
        isLike
      }
    }
  }
`;
export const GET_PERSONAL_GOAL_CATEGORIES = gql`
  query getPersonalGoalCategories($cognitoId: ID!, $targetCognitoId: ID!) {
    getPersonalGoalCategories(cognitoId: $cognitoId, targetCognitoId: $targetCognitoId) {
      id
      title
      description
      createdAt
      goals {
        id
        title
        description
        milestoneType
        officialCertificate
        passed
        eventStartAt
        eventEndAt
      }
    }
  }
`;

export const GET_PERSONAL_GOALS = gql`
  query getPersonalGoals($cognitoId: ID!, $targetCognitoId: ID!) {
    getPersonalGoals(cognitoId: $cognitoId, targetCognitoId: $targetCognitoId) {
      id
      title
      description
      milestoneType
      officialCertificate
      passed
      eventStartAt
      eventEndAt
    }
  }
`;

export const GET_PERSONAL_SETTINGS = gql`
  query getPersonalSettings($cognitoId: ID!, $targetCognitoId: ID!) {
    getPersonalSettings(cognitoId: $cognitoId, targetCognitoId: $targetCognitoId) {
      goalFlagName
    }
  }
`;

export const LIST_TOPIC_CATEGORIES = gql`
  query {
    listTopicCategories {
      id
      title
      createdAt
      updatedAt
    }
  }
`;

export const GET_TOPIC_CATEGORY = gql`
  query getTopicCategory($id: ID!) {
    getTopicCategory(id: $id) {
      id
      title
      createdAt
      updatedAt
    }
  }
`;

export const LIST_GROUPS = gql`
  query {
    listGroups {
      id
      name
      users {
        cognitoId
      }
      createdAt
      updatedAt
    }
  }
`;

export const LIST_GROUP_USERS = gql`
  query listGroupUsers($groupId: ID!) {
    listGroupUsers(groupId: $groupId) {
      group {
        name
      }
      cognitoId
      user {
        firstName
        lastName
        email
      }
    }
  }
`;

export const LIST_PROFILE_CONFIG = gql`
  query {
    listProfileConfig {
      type
      value
    }
  }
`;

export const LIST_CATEGORY = gql`
  query {
    listDocumentCategory {
      category
    }
  }
`;

export const LIST_DOCUMENT_CATEGORY = gql`
  query {
    listDocumentCategory {
      category
    }
    listDocument {
      items {
        pk
        sk
        categoryId
        cognitoId
        file
        user {
          email
          profileImage
          firstName
          lastName
          jobTitle
          companyInfo
          cognitoId
        }
        title
        type
        file
        tags
        comment
        description
        answer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const GET_SELF_NOTE = gql`
  query getSelfNote($cognitoId: ID!, $first: Int, $after: String) {
    getSelfNote(cognitoId: $cognitoId, first: $first, after: $after) {
      nextToken
      notes {
        cognitoId
        content
        createdAt
        id
        title
        updatedAt
      }
    }
  }
`;

export const LIST_QUESTIONNAIRE = gql`
  query listQuestionnaire {
    listQuestionnaire {
      id
      title
      description
      questions {
        id
        question
        createdAt
      }
      users {
        cognitoId
        user {
          email
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_QUESTIONNAIRE_BY_USER_LIST = gql`
  query getQuestionnaireByUser($cognitoId: ID!) {
    getQuestionnaireByUser(cognitoId: $cognitoId) {
      questionnaireId
      questionnaire {
        id
        title
        description
        questions {
          id
        }
      }
      answers {
        answer
      }
      createdAt
    }
  }
`;

export const GET_QUESTIONNAIRE_BY_USER = gql`
  query getQuestionnaireByUser($cognitoId: ID!) {
    getQuestionnaireByUser(cognitoId: $cognitoId) {
      questionnaireId
      questionnaire {
        id
        title
        description
        questions {
          id
          question
        }
      }
      answers {
        id
        questionnaireQuestionId
        answer
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_QUESTIONNAIRE_ANSWER = gql`
  query getQuestionnaireAnswer($questionnaireId: ID!) {
    getQuestionnaireAnswer(questionnaireId: $questionnaireId) {
      questionnaireQuestion {
        question
      }
      user {
        email
      }
      answer
      updatedAt
    }
  }
`;

export const GET_ADMIN_CONFIG = gql`
  query getAdminConfig($key: String!) {
    getAdminConfig(key: $key) {
      value
    }
  }
`;

export const LIST_BLOG_POST = gql`
  query {
    listBlogPost {
      id
      cognitoId
      user {
        email
        profileImage
        firstName
        lastName
        jobTitle
        companyInfo
      }
      sectionId
      section {
        name
        color
      }
      title
      image
      tags
      content
      isPublished
      views
      likes {
        createdAt
        cognitoId
        user {
          profileImage
          firstName
          email
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const LIST_BLOG_SECTION = gql`
  query {
    listBlogSection {
      id
      name
      color
    }
  }
`;

export const GET_BLOG_POST = gql`
  query getBlogPost($id: ID!) {
    getBlogPost(id: $id) {
      id
      cognitoId
      user {
        email
        profileImage
        firstName
        lastName
        jobTitle
        companyInfo
      }
      sectionId
      section {
        name
        color
      }
      title
      image
      tags
      content
      isPublished
      views
      likes {
        createdAt
        cognitoId
        user {
          profileImage
          firstName
          email
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_VISIONS = gql`
  query getVisions($cognitoId: ID!) {
    getVisions(cognitoId: $cognitoId) {
      id
      cognitoId
      # user: User
      targetCognitoId
      targetUser {
        cognitoId
        firstName
        lastName
        profileImage
        email
        jobTitle
        companyInfo
      }
      title
      category
      status
      publishedAt
      description
      createdAt
      updatedAt
    }
  }
`;

export const GET_VISIONS_FOR_COACH = gql`
  query getVisionsForCoach($cognitoId: ID!, $targetCognitoId: ID!) {
    getVisionsForCoach(cognitoId: $cognitoId, targetCognitoId: $targetCognitoId) {
      id
      cognitoId
      # user: User
      targetCognitoId
      targetUser {
        firstName
        lastName
        profileImage
        email
      }
      title
      category
      status
      publishedAt
      description
      createdAt
      updatedAt
    }
  }
`;

export const GET_VISION_DETAIL = gql`
  query getVisionDetail($id: ID!) {
    getVisionDetail(id: $id) {
      id
      cognitoId
      # user: User
      targetCognitoId
      title
      category
      status
      publishedAt
      description
      createdAt
      updatedAt
    }
  }
`;

export const GET_EVENT = gql`
  query getEvent($id: ID!) {
    getEvent(id: $id) {
      allDay
      cognitoId
      user {
        cognitoId
        email
        firstName
        lastName
        jobTitle
        profileImage
        companyInfo
      }
      createdAt
      description
      endDate
      id
      startDate
      targetCognitoId
      targetUser {
        cognitoId
        email
        firstName
        lastName
        jobTitle
        profileImage
        companyInfo
      }
      textColor
      title
      statusEvent
      updatedAt
    }
  }
`;

export const GET_EVENTS = gql`
  query getEvents($cognitoId: ID!, $startDate: String!, $endDate: String!, $targetCognitoId: ID) {
    getEvents(
      cognitoId: $cognitoId
      startDate: $startDate
      endDate: $endDate
      targetCognitoId: $targetCognitoId
    ) {
      allDay
      cognitoId
      user {
        cognitoId
        email
        firstName
        lastName
        jobTitle
        profileImage
        companyInfo
      }
      createdAt
      description
      endDate
      id
      startDate
      targetCognitoId
      targetUser {
        cognitoId
        email
        firstName
        lastName
        jobTitle
        profileImage
        companyInfo
      }
      textColor
      title
      statusEvent
      updatedAt
    }
  }
`;

export const GET_INVITATIONS_LIST = gql`
  query getInvitationsList($targetCognitoId: ID!, $startDate: String!, $endDate: String!) {
    getInvitationsList(
      targetCognitoId: $targetCognitoId
      startDate: $startDate
      endDate: $endDate
    ) {
      allDay
      cognitoId
      user {
        cognitoId
        email
        firstName
        lastName
        jobTitle
        profileImage
        companyInfo
      }
      createdAt
      description
      endDate
      id
      startDate
      targetCognitoId
      targetUser {
        cognitoId
        email
        firstName
        lastName
        jobTitle
        profileImage
        companyInfo
      }
      textColor
      title
      statusEvent
      updatedAt
    }
  }
`;

export const GET_NOTIFICATION_HISTORY_LIST = gql`
  query getNotificationHistory($recipientCognitoId: ID!) {
    getNotificationHistory(recipientCognitoId: $recipientCognitoId) {
      createdAt
      id
      type
      read
      updatedAt
      recipient {
        firstName
        email
        jobTitle
        lastName
        profileImage
        cognitoId
      }
      sender {
        email
        firstName
        jobTitle
        lastName
        profileImage
        cognitoId
      }
      event {
        allDay
        cognitoId
        user {
          cognitoId
          email
          firstName
          lastName
          jobTitle
          profileImage
          companyInfo
        }
        createdAt
        description
        endDate
        id
        startDate
        targetCognitoId
        targetUser {
          cognitoId
          email
          firstName
          lastName
          jobTitle
          profileImage
          companyInfo
        }
        textColor
        title
        statusEvent
        updatedAt
      }
    }
  }
`;

export const GET_QUESTION = gql`
  query getQuestion($pk: String!, $sk: String!) {
    getQuestion(pk: $pk, sk: $sk) {
      pk
      sk
      categoryId
      cognitoId
      targetCognitoId
      title
      description
      answer
      status
      published
      type
      file
      tags
      createdAt
      updatedAt
      user {
        email
        profileImage
        firstName
        lastName
        jobTitle
        companyInfo
        cognitoId
      }
      targetUser {
        email
        profileImage
        firstName
        lastName
        jobTitle
        companyInfo
        cognitoId
      }
    }
  }
`;

export const LIST_QUESTION = gql`
  query listQuestion($cognitoId: ID, $limit: Int, $nextToken: String) {
    listQuestion(cognitoId: $cognitoId, limit: $limit, nextToken: $nextToken) {
      items {
        pk
        sk
        categoryId
        cognitoId
        targetCognitoId
        title
        description
        answer
        status
        published
        type
        file
        tags
        createdAt
        updatedAt
        user {
          email
          profileImage
          firstName
          lastName
          jobTitle
          companyInfo
          cognitoId
        }
        targetUser {
          email
          profileImage
          firstName
          lastName
          jobTitle
          companyInfo
          cognitoId
        }
      }
      nextToken
    }
  }
`;

export const GET_JITSI_SETTING = gql`
  query getJitsiSetting {
    getJitsiSetting {
      id
      key
      value
    }
  }
`;
