import { createSlice } from '@reduxjs/toolkit';
// @types
import { CommonState } from '../../@types/redux/common';

// ----------------------------------------------------------------------

const initialState: CommonState = {
  loadingLayer: false,
  loadingScreen: false,
};

const slice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    // SET loadingLayer
    setLoadingLayer(state, action) {
      state.loadingLayer = action.payload;
    },
    // SET loadingScreen
    setLoadingScreen(state, action) {
      state.loadingScreen = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setLoadingLayer, setLoadingScreen } = slice.actions;
