import { createSlice } from '@reduxjs/toolkit';
//
import { UserLoginState } from '../../@types/auth';

// ----------------------------------------------------------------------

const initialState: UserLoginState = {
  email: '',
  password: '',
  mfaType: null,
  userConfirmed: true,
};

const slice = createSlice({
  name: 'userLoginData',
  initialState,
  reducers: {
    onVerifyStep(state, action) {
      state.email = action.payload.email;
      state.password = action.payload.password;
      state.userConfirmed = action.payload.userConfirmed;
      if (action.payload.mfaType) {
        state.mfaType = action.payload.mfaType;
      }
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { onVerifyStep } = slice.actions;
