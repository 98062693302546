import { useTranslation } from 'react-i18next';
// @mui
import { enUS, nlNL } from '@mui/material/locale';

// ----------------------------------------------------------------------

const LANGS = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/icons/ic_flag_en.svg',
  },
  {
    label: 'Dutch',
    value: 'nl',
    systemValue: nlNL,
    icon: '/icons/ic_flag_nl.svg',
  },
  // {
  //   label: 'German',
  //   value: 'de',
  //   systemValue: deDE,
  //   icon: '/icons/ic_flag_de.svg',
  // },
  // {
  //   label: 'French',
  //   value: 'fr',
  //   systemValue: frFR,
  //   icon: '/icons/ic_flag_fr.svg',
  // },
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[1];

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
  };

  const localeRoute = (path: string, addLangPrefix?: boolean) => {
    if (
      (typeof addLangPrefix === 'undefined' && path.startsWith('/content/')) ||
      (typeof addLangPrefix !== 'undefined' && addLangPrefix)
    ) {
      return `/${langStorage}${path}`;
    }

    return path;
  };

  return {
    onChangeLang: handleChangeLanguage,
    localeRoute,
    translate,
    currentLang,
    allLang: LANGS,
  };
}
