import { createContext, ReactNode, useEffect, useState } from 'react';
// @types
import { NotificationContextType, NotificationType } from '../@types/notification';
// config
import { APP_CONFIG } from '../config';
// hooks
import { client as appsyncClient } from '../hooks/useAppsync';
import useAuth from '../hooks/useAuth';
// graphql
import { SUBSCRIPTION_MY_NOTIFICATION } from '../graphql/subscriptions';
import { CREATE_NOTIFICATION } from '../graphql/mutations';
// sections
import { IncomingCall } from '../sections/video';
import { dispatch, useSelector } from '../redux/store';
import {
  closeCalling,
  setCaller,
  setIncomingCall,
  setReceiver,
  startCalling,
  setReceiverList,
  setJitsiRoomName,
  resetCalling,
  acceptCalling,
} from '../redux/slices/call';
// sounds
import { BellNotification, HappyBellNotification } from '../assets/sounds';
import JitsiCall from '../sections/video/JitsiCall';

// ----------------------------------------------------------------------
const NotificationContext = createContext<NotificationContextType | null>(null);

// ----------------------------------------------------------------------
type NotificationProviderProps = {
  children: ReactNode;
};

const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const { user } = useAuth();
  const { caller, receiver, hasIncomingCall, isCalling, receiverList } = useSelector(
    (state) => state.call
  );
  const [notificationPermission, setNotificationPermission] = useState('');
  const [soundChat] = useState(new Audio(BellNotification));
  const [soundCall] = useState(new Audio(HappyBellNotification));

  // NOTIFICATION permission
  useEffect(() => {
    if (!notificationPermission && 'Notification' in window) {
      Notification.requestPermission().then((r) => {
        setNotificationPermission(r);
      });
    }
  }, [notificationPermission]);

  // SHOW NOTIFICATION
  const showNotification = (data: NotificationType, message: string | null) => {
    const options = {
      body: `${data.sender.firstName || data.sender.email}: ${message || data.message}`,
    };

    // Show message content
    new Notification(APP_CONFIG.appTitle, options);
    // message.onclick = (event) => {
    //   event.preventDefault(); // prevent the browser from focusing the Notification's tab
    //   navigate(`${PATH_DASHBOARD.user.root}/${data.sender.cognitoId}/chat`);
    // };
  };

  // SUBSCRIBE all notification
  useEffect(() => {
    // SUBSCRIBE NOTIFICATION
    appsyncClient
      .subscribe({
        query: SUBSCRIPTION_MY_NOTIFICATION,
        variables: {
          receiverCognitoId: user?.sub || '',
        },
      })
      .subscribe({
        next(data) {
          const notification = data.data.subscribeToMyNotification;
          switch (notification.type) {
            // HANDLE CHAT
            case 'chat':
              if (notificationPermission === 'granted') {
                showNotification(notification, null);
              }
              soundChat.volume = 0.1;
              soundChat.play();
              break;

            // HANDLE INCOMING CALL
            case 'call-jitsi':
              if (notificationPermission === 'granted' && notification.status === 'caller.start') {
                showNotification(notification, ' is calling you');
              }
              if (notification.status === 'caller.start') {
                console.log('caller.start', notification);
                dispatch(setIncomingCall(true)); // hasIncoming
                dispatch(setCaller(notification.sender));
                dispatch(setReceiver(notification.receiver));
                dispatch(setJitsiRoomName(notification.conversationId));
                soundCall.play().then((r) => (soundCall.loop = true));
              } else if (notification.status === 'receiver.accept') {
                console.log('receiver.accept', notification);
                dispatch(acceptCalling({ receiverId: notification.sender.cognitoId }));
                stopSound();
              } else if (notification.status === 'call.close') {
                console.log('call.close', notification);
                dispatch(closeCalling({ receiverId: notification.sender.cognitoId }));
                stopSound();
              } else if (notification.status === 'call.force-close') {
                console.log('call.force-close', notification);
                dispatch(resetCalling());
                stopSound();
              }
              break;
          }
        },
      });
    // eslint-disable-next-line
  }, [notificationPermission, user?.sub]);

  // SEND NOTIFICATION
  const sendPeerMessage = (
    _senderId: string,
    _receiverId: string,
    _type: string,
    _status: string,
    _message: string,
    _conversationId?: string
  ) => {
    appsyncClient.mutate({
      variables: {
        conversationId: _conversationId || 'no-need',
        parentId: null,
        senderCognitoId: _senderId,
        receiverCognitoId: _receiverId,
        type: _type,
        status: _status,
        message: _message, // receiverSignalData
      },
      mutation: CREATE_NOTIFICATION,
    });
  };

  const stopSound = () => {
    soundCall.pause();
    soundCall.currentTime = 0;
  };

  // Handle video call with Jitsi
  const startCallJitsi = (callerId: string, usersId: string[], conversationId: string) => {
    soundCall.play().then((r) => (soundCall.loop = true));
    usersId.forEach((receiverId) => {
      sendPeerMessage(
        callerId,
        receiverId,
        'call-jitsi',
        'caller.start',
        'requestedCall',
        conversationId
      );
    });
    dispatch(
      setReceiverList(usersId.map((id: string) => ({ userId: id, callingStatus: 'pendding' })))
    );
    dispatch(setJitsiRoomName(conversationId));
    dispatch(startCalling(true));
  };

  const acceptCallJitsi = () => {
    sendPeerMessage(
      receiver.cognitoId,
      caller.cognitoId,
      'call-jitsi',
      'receiver.accept',
      'acceptedCall'
    );
    dispatch(startCalling(true));
    stopSound();
    // dispatch(answerIncomingCalling(true));
  };

  const handleCloseJitsiCalling = (senderId: string, receiverId: string) => {
    if (senderId && receiverId) {
      sendPeerMessage(senderId, receiverId, 'call-jitsi', 'call.close', 'ClosedCall');
    }
    stopSound();
    dispatch(resetCalling());
  };

  const handlerCloseCallingForAll = () => {
    receiverList.forEach((receiver) => {
      sendPeerMessage(user?.sub, receiver.userId, 'call-jitsi', 'call.force-close', 'foreClose');
    });
    stopSound();
    dispatch(resetCalling());
  };

  return (
    <NotificationContext.Provider
      value={{
        handleCloseJitsiCalling,
        handlerCloseCallingForAll,
        startCallJitsi,
        acceptCallJitsi,
      }}
    >
      {isCalling && <JitsiCall />}
      {hasIncomingCall && <IncomingCall />}
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationContext, NotificationProvider };
