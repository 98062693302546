// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_ADMIN = '/admin';
const ROOTS_AUTH = '/auth';
const ROOTS_CONTENT = '/content';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  root: ROOTS_CONTENT,
  comingSoon: '/coming-soon',
  contact: path(ROOTS_CONTENT, '/contact-us'),
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  payment: '/payment',
  privacyPolicy: path(ROOTS_CONTENT, '/privacy-policy'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    app: path(ROOTS_DASHBOARD, '/app'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    document: path(ROOTS_DASHBOARD, '/document'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    forum: path(ROOTS_DASHBOARD, '/forum'),
    newDocument: path(ROOTS_DASHBOARD, '/document/new'),
    newForum: path(ROOTS_DASHBOARD, '/forum/new'),
    newNote: path(ROOTS_DASHBOARD, '/note/new'),
    newVision: path(ROOTS_DASHBOARD, '/vision/new'),
    note: path(ROOTS_DASHBOARD, '/note'),
    notification: path(ROOTS_DASHBOARD, '/notification'),
    personality: path(ROOTS_DASHBOARD, '/personality'),
    questionnaire: path(ROOTS_DASHBOARD, '/questionnaire'),
    vision: path(ROOTS_DASHBOARD, '/vision'),
  },

  askQuestion: {
    root: path(ROOTS_DASHBOARD, '/q&a'),
    list: path(ROOTS_DASHBOARD, '/q&a/list'),
    new: path(ROOTS_DASHBOARD, '/q&a/new'),
    edit: path(ROOTS_DASHBOARD, '/q&a/edit'),
  },

  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    newPost: path(ROOTS_DASHBOARD, '/blog/post/new'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:id'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (conversationId: string) => path(ROOTS_DASHBOARD, `/chat/${conversationId}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  groupCall: path(ROOTS_DASHBOARD, '/group-call'),
};

export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  user: {
    root: path(ROOTS_ADMIN, '/user'),
    group: path(ROOTS_ADMIN, '/user/group'),
    groupList: path(ROOTS_ADMIN, '/user/group/list'),
    newGroup: path(ROOTS_ADMIN, '/user/group/new'),
    list: path(ROOTS_ADMIN, '/user/list'),
    profile: path(ROOTS_ADMIN, '/user/profile'),
  },
  blog: {
    root: path(ROOTS_ADMIN, '/blog'),
    list: path(ROOTS_ADMIN, '/blog/allow-users'),
    sections: path(ROOTS_ADMIN, '/blog/sections'),
    newSection: path(ROOTS_ADMIN, '/blog/sections/new'),
  },
  questionnaire: {
    root: path(ROOTS_ADMIN, '/questionnaire'),
    list: path(ROOTS_ADMIN, '/questionnaire/list'),
    new: path(ROOTS_ADMIN, '/questionnaire/new'),
    thanks: path(ROOTS_ADMIN, '/questionnaire/thanks'),
  },
  document: path(ROOTS_ADMIN, '/document'),
  menu: path(ROOTS_ADMIN,'/menu'),
};

// export const LANG_MATCH = {
//   'en': {
//     'contact-us': '/contact-us'
//   },
//   'nl': {
//     'contact-us': '/contact-us'
//   },
// }

export const PATH_DOCS = 'https://tr2.it';
