// routes
import { PATH_ADMIN, PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  contacts: getIcon('ic_contacts'),
  dashboard: getIcon('ic_dashboard'),
  eye: getIcon('ic_eye'),
  forum: getIcon('ic_forum'),
  help: getIcon('ic_help'),
  note: getIcon('ic_note'),
  menu: getIcon('ic_menu'),
  person: getIcon('ic_person'),
  source: getIcon('ic_source'),
  settings: getIcon('ic_settings'),
};

const navConfig = [
  // Management
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      { title: 'dashboard', path: PATH_ADMIN.root, icon: ICONS.dashboard },
      {
        title: 'user',
        path: PATH_DASHBOARD.user.account,
        icon: ICONS.person,
        children: [
          { title: 'group', path: PATH_ADMIN.user.group },
          { title: 'list', path: PATH_ADMIN.user.list },
          { title: 'profile Config', path: PATH_ADMIN.user.profile },
        ],
      },
      {
        title: 'questionnaire',
        path: PATH_DASHBOARD.general.questionnaire,
        icon: ICONS.help,
        children: [
          { title: 'list', path: PATH_ADMIN.questionnaire.list },
          { title: 'Thank you', path: PATH_ADMIN.questionnaire.thanks },
        ],
      },
      { title: 'document', path: PATH_ADMIN.document, icon: ICONS.source },
      {
        title: 'blog',
        path: PATH_DASHBOARD.blog.posts,
        icon: ICONS.blog,
        children: [
          { title: 'allowed users', path: PATH_ADMIN.blog.list },
          { title: 'sections', path: PATH_ADMIN.blog.sections },
        ],
      },
    ],
  },
  {
    subheader: 'systems',
    items: [
      { title: 'general', path: '', icon: ICONS.settings },
      { title: 'menu', path: PATH_ADMIN.menu, icon: ICONS.menu },
    ]
  },
  // Settings
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'settings',
  //   items: [{ title: 'user_fields', path: PATH_DASHBOARD.general.forum, icon: ICONS.person }],
  // },
];

export default navConfig;
