import { ElementType, lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// guards
import useAuth from '../hooks/useAuth';
// layouts
import AdminLayout from '../layouts/admin';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        }, // { path: 'login-unprotected', element: <Login /> },
        // { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'app',
          element: <GeneralApp />,
        },
        {
          path: 'q&a',
          children: [
            { element: <Navigate to="/dashboard/q&a/list" replace />, index: true },
            { path: 'list', element: <AskQuestionList /> },
            { path: 'new', element: <AskQuestionCreate /> },
            { path: 'edit', element: <AskQuestionCreate /> },
          ],
        },
        {
          path: 'notification',
          element: <NotificationPage />,
        },
        {
          path: 'document',
          children: [
            { element: <Navigate to="/dashboard/document/list" replace />, index: true },
            {
              path: 'list',
              element: <DocumentLibrary />,
            },
            { path: 'new', element: <DocumentCreate /> },
          ],
        },
        {
          path: 'note',
          children: [
            { element: <Navigate to="/dashboard/note/list" replace />, index: true },
            {
              path: 'list',
              element: <NoteList />,
            },
            { path: 'new', element: <NoteCreate /> },
            { path: ':id/edit', element: <NoteCreate /> },
          ],
        },
        {
          path: 'questionnaire',
          children: [
            { element: <Navigate to="/dashboard/questionnaire/list" replace />, index: true },
            {
              path: 'list',
              element: <Questionnaire />,
            }, // { path: 'new', element: <DocumentCreate /> },
            // { path: ':id/edit', element: <DocumentCreate /> },
            { path: ':id', element: <QuestionnaireAnswerIntroduction /> },
          ],
        },
        {
          path: 'personality',
          children: [
            { element: <Navigate to="/404" replace />, index: true },
            { path: ':token', element: <PersonalityResult /> },
          ],
        },
        {
          path: 'user',
          children: [
            {
              path: ':id',
              element: <UserProfile />,
              children: [
                {
                  path: ':name',
                  element: <UserProfile />,
                },
              ],
            },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            {
              path: 'new',
              element: <UserCreate />,
            },
            { path: ':name/edit', element: <UserCreate /> },
            {
              path: 'account',
              element: <UserAccount />,
              children: [{ path: ':name', element: <UserAccount /> }],
            },
          ],
        },
        {
          path: 'vision',
          children: [
            { element: <Navigate to="/dashboard/vision/list" replace />, index: true },
            {
              path: 'list',
              element: <VisionList />,
            },
            { path: 'new', element: <VisionCreate /> },
            { path: ':id/edit', element: <VisionCreate /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            {
              path: 'posts',
              element: <BlogPosts />,
            },
            { path: 'post/:id', element: <BlogPost /> },
            { path: 'post/new', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'forum',
          children: [
            { element: <Navigate to="/dashboard/forum/list" replace />, index: true },
            {
              path: 'list',
              element: <ForumList />,
            },
            { path: 'new', element: <ForumCreate /> },
            { path: ':id/edit', element: <ForumCreate /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'group-call', element: <GroupCall /> },
      ], // {
      //   path: 'e-commerce',
      //   children: [
      //     { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
      //     { path: 'shop', element: <EcommerceShop /> },
      //     { path: 'product/:name', element: <EcommerceProductDetails /> },
      //     { path: 'list', element: <EcommerceProductList /> },
      //     { path: 'product/new', element: <EcommerceProductCreate /> },
      //     { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
      //     { path: 'checkout', element: <EcommerceCheckout /> },
      //     { path: 'invoice', element: <EcommerceInvoice /> },
      //   ],
      // },
      // {
      //   path: 'mail',
      //   children: [
      //     { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
      //     { path: 'label/:customLabel', element: <Mail /> },
      //     { path: 'label/:customLabel/:mailId', element: <Mail /> },
      //     { path: ':systemLabel', element: <Mail /> },
      //     { path: ':systemLabel/:mailId', element: <Mail /> },
      //   ],
      // },
      // { path: 'kanban', element: <Kanban /> },
      // { path: 'ecommerce', element: <GeneralEcommerce /> },
      // { path: 'analytics', element: <GeneralAnalytics /> },
      // { path: 'banking', element: <GeneralBanking /> },
      // { path: 'booking', element: <GeneralBooking /> },
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        // { path: 'coming-soon', element: <ComingSoon /> },
        // { path: 'maintenance', element: <Maintenance /> },
        // { path: 'payment', element: <Payment /> },
        // { path: 'pricing', element: <Pricing /> },
        { path: '*', element: <Navigate to="/404" replace /> },
        { path: '404', element: <NotFound /> },
        {
          path: '500',
          element: <Page500 />,
        },
      ],
    },
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },
    { path: 'privacy-policy', element: <Privacy /> },
    { path: '*', element: <Navigate to="/404" replace /> },

    // Admin routes
    {
      path: 'admin',
      element: (
        <RoleBasedGuard accessibleRoles={['Administrators']}>
          <AdminLayout />
        </RoleBasedGuard>
      ),
      children: [
        { element: <AdminPage />, index: true },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/admin/user/group" replace />, index: true },
            {
              path: 'group',
              element: <AdminGroup />,
            },
            {
              path: 'group/new',
              element: <AdminGroupCreate />,
            },
            {
              path: 'group/:id/edit',
              element: <AdminGroupCreate />,
            },
            { element: <Navigate to="/admin/user/list" replace />, index: true },
            {
              path: 'profile',
              element: <AdminProfileConfig />,
            },
            {
              path: ':id/edit',
              element: <AdminUserCreate />,
            },
            {
              path: 'list',
              element: <AdminUserList />,
            },
          ],
        },
        {
          path: 'questionnaire',
          children: [
            { element: <Navigate to="/admin/questionnaire/list" />, index: true },
            { path: 'list', element: <AdminQuestionnaireList /> },
            { path: 'new', element: <AdminQuestionnaireCreate /> },
            { path: ':id/edit', element: <AdminQuestionnaireCreate /> },
            { path: 'thanks', element: <AdminThankYou /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/admin/blog/allow-users" />, index: true },
            { path: 'allow-users', element: <AllowedUsers /> },
            { path: 'sections', element: <Sections /> },
            { path: 'sections/new', element: <SectionCreate /> },
            { path: 'sections/:id/edit', element: <SectionCreate /> },
          ],
        },
        { path: 'document', element: (<AdminDocumentLibrary />) },
        { path: 'menu', element: (<AdminMenu />) }
      ],
    },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// Dashboard
const AskQuestionList = Loadable(lazy(() => import('../pages/dashboard/AskQuestionList')));
const AskQuestionCreate = Loadable(lazy(() => import('../pages/dashboard/AskQuestionCreate')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const DocumentCreate = Loadable(lazy(() => import('../pages/dashboard/DocumentCreate')));
const DocumentLibrary = Loadable(lazy(() => import('../pages/dashboard/DocumentLibrary')));
const ForumList = Loadable(lazy(() => import('../pages/dashboard/ForumList')));
const ForumCreate = Loadable(lazy(() => import('../pages/dashboard/ForumCreate')));
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const NotificationPage = Loadable(
  lazy(() => import('../sections/@dashboard/header/NotificationPage'))
);
const NoteCreate = Loadable(lazy(() => import('../pages/dashboard/NoteCreate')));
const NoteList = Loadable(lazy(() => import('../pages/dashboard/NoteList')));
const Questionnaire = Loadable(lazy(() => import('../pages/dashboard/Questionnaire')));
const PersonalityResult = Loadable(lazy(() => import('../pages/dashboard/PersonalityResult')));
const QuestionnaireAnswerIntroduction = Loadable(
  lazy(() => import('../pages/dashboard/QuestionnaireAnswerIntroduction'))
);
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const VisionList = Loadable(lazy(() => import('../pages/dashboard/VisionList')));
const VisionCreate = Loadable(lazy(() => import('../pages/dashboard/VisionCreate')));
const GroupCall = Loadable(lazy(() => import('../pages/dashboard/GroupCall')));
// Main
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Privacy = Loadable(lazy(() => import('../pages/PrivacyPolicy')));
// Admin
const AdminPage = Loadable(lazy(() => import('../pages/admin/Home')));
const AdminGroup = Loadable(lazy(() => import('../pages/admin/AdminGroupList')));
const AdminGroupCreate = Loadable(lazy(() => import('../pages/admin/AdminGroupCreate')));
const AdminProfileConfig = Loadable(lazy(() => import('../pages/admin/AdminProfileConfig')));
const AllowedUsers = Loadable(lazy(() => import('../pages/admin/blog/AllowedUsers')));
const SectionCreate = Loadable(lazy(() => import('../pages/admin/blog/SectionCreate')));
const Sections = Loadable(lazy(() => import('../pages/admin/blog/Sections')));
const AdminUserList = Loadable(lazy(() => import('../pages/admin/AdminUserList')));
const AdminUserCreate = Loadable(lazy(() => import('../pages/admin/AdminUserCreate')));
const AdminQuestionnaireList = Loadable(
  lazy(() => import('../pages/admin/questionnaire/AdminQuestionnaireList'))
);
const AdminQuestionnaireCreate = Loadable(
  lazy(() => import('../pages/admin/questionnaire/AdminQuestionnaireCreate'))
);
const AdminThankYou = Loadable(lazy(() => import('../pages/admin/questionnaire/AdminThankYou')));
const AdminDocumentLibrary = Loadable(lazy( () => import('../pages/admin/AdminDocumentLibrary')));
const AdminMenu = Loadable(lazy( () => import('../pages/admin/AdminMenu')))

// Dashboard
// const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
// const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
// const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
// const EcommerceProductDetails = Loadable(
//   lazy(() => import('../pages/dashboard/EcommerceProductDetails'))
// );
// const EcommerceProductList = Loadable(
//   lazy(() => import('../pages/dashboard/EcommerceProductList'))
// );
// const EcommerceProductCreate = Loadable(
//   lazy(() => import('../pages/dashboard/EcommerceProductCreate'))
// );
// const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
// const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
// const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
// const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
// const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
// const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));

// Main
// const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
// const Contact = Loadable(lazy(() => import('../pages/Contact')));
// const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
// const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
// const Payment = Loadable(lazy(() => import('../pages/Payment')));
// const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
