import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { alpha, styled } from '@mui/material/styles';
import {
  Autocomplete,
  Avatar,
  ClickAwayListener,
  InputAdornment,
  Link,
  Popper,
  PopperProps,
  Slide,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { userDisplayName } from '../../../hooks/useUser';
import useAuth from '../../../hooks/useAuth';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// @types
import { UserData } from '../../../@types/user';
// components
import Iconify from '../../../components/Iconify';
import SearchNotFound from '../../../components/SearchNotFound';
import { IconButtonAnimate } from 'src/components/animate';
// untils
import cssStyles from '../../../utils/cssStyles';
import getUserAvatar from '../../../utils/getUserAvatar';
import createAvatar from '../../../utils/createAvatar';
// graphql
import { LIST_USERS } from '../../../graphql/queries';
import useLocales from '../../../hooks/useLocales';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [ theme.breakpoints.up('md') ]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const autocompleteStyle = {
  width: 1,
  height: 1,
  '& .MuiFormControl-root, .MuiOutlinedInput-root, .MuiAutocomplete-input': {
    height: 1,
    p: '0 !important',
    fontWeight: 'fontWeightBold',
    '& fieldset': { display: 'none' },
  },
};

const PopperStyle = styled((props: PopperProps) => <Popper {...props} />)(({ theme }) => ({
  left: `8px !important`,
  top: `${APPBAR_MOBILE + 8}px !important`,
  width: 'calc(100% - 16px) !important',
  transform: 'none !important',
  [ theme.breakpoints.up('md') ]: {
    top: `${APPBAR_DESKTOP + 8}px !important`,
  },
  '& .MuiAutocomplete-paper': {
    padding: theme.spacing(1, 0),
  },
  '& .MuiListSubheader-root': {
    '&.MuiAutocomplete-groupLabel': {
      ...cssStyles(theme).bgBlur({ color: theme.palette.background.neutral }),
      ...theme.typography.overline,
      top: 0,
      margin: 0,
      lineHeight: '48px',
      borderRadius: theme.shape.borderRadius,
    },
  },
  '& .MuiAutocomplete-listbox': {
    '& .MuiAutocomplete-option': {
      padding: theme.spacing(0.5, 2),
      margin: 0,
      display: 'block',
      border: `dashed 1px transparent`,
      borderBottom: `dashed 1px ${theme.palette.divider}`,
      '&:hover': {
        border: `dashed 1px ${theme.palette.primary.main}`,
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
      },
    },
  },
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const { user } = useAuth();
  const isMountedRef = useIsMountedRef();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<UserData[] | []>([]);
  const [userList, setUserList] = useState<UserData[] | []>([]);
  const { data } = useQuery(LIST_USERS);
  const { translate } = useLocales();

  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSetUser = (userList: any) => {
    setUserList(
      userList.map((el: UserData) => ({
        ...el,
        name: userDisplayName({
          email: el.email,
          firstName: el.firstName ?? '',
          lastName: el.lastName ?? '',
        }),
        jobTitle: el.jobTitle ?? '',
      })),
    );
  };
  useEffect(() => {
    if (data) {
      handleSetUser(data.listUsers);
    }
  }, [data]);

  const handleChangeSearch = async (value: string) => {
    try {
      setSearchQuery(value);
      if (value) {
        if (isMountedRef.current) {
          setSearchResults(userList);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <IconButtonAnimate onClick={handleOpen}>
            <Iconify icon={'eva:search-fill'} width={20} height={20} />
          </IconButtonAnimate>
        )}

        <Slide direction='down' in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Autocomplete
              sx={autocompleteStyle}
              autoHighlight
              disablePortal
              disableClearable
              fullWidth
              PopperComponent={PopperStyle}
              popupIcon={null}
              options={searchResults}
              onInputChange={(event, value) => handleChangeSearch(value)}
              getOptionLabel={(userList: UserData) => userList.name}
              noOptionsText={<SearchNotFound searchQuery={searchQuery} />}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoFocus
                  placeholder={translate('common.search')}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Iconify
                          icon={'eva:search-fill'}
                          sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              renderOption={(props, userList) => {
                const { name, profileImage, id, cognitoId } = userList;
                let avatarUrl = profileImage;
                if (avatarUrl) {
                  avatarUrl = getUserAvatar(userList);
                }
                return (
                  <li {...props}>
                    {cognitoId !== user?.sub && (
                      <Link
                        underline='none'
                        to={
                          (id || cognitoId) !== user?.sub
                            ? `${PATH_DASHBOARD.user.root}/${id || cognitoId}/personal`
                            : '#'
                        }
                        component={RouterLink}
                      >
                        <Stack direction={'row'}>
                          <Avatar
                            alt={name}
                            src={avatarUrl}
                            color={avatarUrl ? 'default' : createAvatar(name).color}
                            sx={{
                              mr: 2,
                              borderStyle: 'solid',
                              borderColor: 'common.white',
                            }}
                          >
                            {createAvatar(name).name}
                          </Avatar>
                          <Typography
                            variant='subtitle2'
                            sx={{ mt: 1.3 }}
                          >
                            {name}
                          </Typography>

                        </Stack>
                      </Link>
                    )}
                  </li>
                );
              }}
            />
          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
