import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
// query
import { useMutation } from '@apollo/client';
import { UPDATE_USER_PROFILE } from '../../graphql/mutations';
import { GET_USER_PROFILE } from '../../graphql/queries';
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

export default function CookieConsent() {
  const [open, setOpen] = React.useState(true);
  const { user } = useAuth();
  const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE);

  const handleAccept = async () => {
    setOpen(false);
    const input = {
      cookieAccept: true,
    };
    await updateUserProfile({
      variables: {
        cognitoId: user?.sub,
        input: input,
      }, // Update cache
      update(cache) {
        cache.updateQuery(
          {
            query: GET_USER_PROFILE,
            variables: {
              cognitoId: user?.sub,
            },
          },
          (profile) => ({
            getUser: {
              ...profile.getUser,
              ...input,
            },
          })
        );
      },
    });
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Cookie Policy</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This Cookie policy explains what cookies are and how we use them. You should read this
          policy so you can understand what type of cookies we use, the information we collect using
          cookies and how that information is used. For further information on how we use, store and
          keep your personal data secure, see our&nbsp;
          <Link underline="always" to={PATH_PAGE.privacyPolicy} component={RouterLink}>
            Privacy Policy
          </Link>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" autoFocus onClick={handleAccept}>
          I AGREE
        </Button>
      </DialogActions>
    </Dialog>
  );
}
