import { Helmet } from 'react-helmet-async';
import { forwardRef, ReactNode } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';
import { APP_CONFIG } from '../config';
import { useSelector } from '../redux/store';
// components
import LoadingScreen from './LoadingScreen';
import LoadingLayer from './LoadingLayer';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  children: ReactNode;
  meta?: ReactNode;
  title: string;
}
const Page = forwardRef<HTMLDivElement, Props>(({ children, title = '', meta, ...other }, ref) => {
  const { loadingLayer, loadingScreen } = useSelector((state) => state.common);
  return (
    <>
      <Helmet>
        <title>{`${title} | ${APP_CONFIG.appTitle}`}</title>
        {meta}
      </Helmet>

      <Box ref={ref} {...other}>
        {loadingScreen && <LoadingScreen />}
        {loadingLayer && <LoadingLayer />}
        {children}
      </Box>
    </>
  );
});

export default Page;
