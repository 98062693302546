import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';

// -----------------------------------------------------------------------------
interface IProps extends AutocompleteProps<any, any, any, any> {
  name: string;
  options: any;
  label: string;
  placeholder?: string;
}

export default function RHFAutocomplete({
  name,
  options,
  label,
  placeholder,
  ...other
}: IProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          {...other}
          value={value}
          options={options}
          onChange={(event, newValue) => onChange(newValue)}
          renderInput={(params) => (
            <TextField
              label={label}
              placeholder={placeholder}
              error={!!error}
              helperText={error?.message}
              {...params}
            />
          )}
        />
      )}
    />
  );
}
