// @mui
import { Dialog, DialogContent } from '@mui/material';
// @types
import { JitsiMeeting } from '@jitsi/react-sdk';
import { useSelector } from '../../redux/store';
import useNotification from '../../hooks/useNotification';
import useAuth from '../../hooks/useAuth';
import { GET_JITSI_SETTING } from 'src/graphql/queries';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
// ----------------------------------------------------------------------
export default function JitsiCall() {
  const { user } = useAuth();

  // Redux
  const { isCalling, jitsiRoomName, caller, receiver } = useSelector((state) => state.call);

  // GraphQL
  const { handleCloseJitsiCalling, handlerCloseCallingForAll } = useNotification();
  const { data: jitsiSetting } = useQuery(GET_JITSI_SETTING);

  // State
  const [jitsiDomain, setJitsiDomain] = useState();

  // Function handle
  const handleCloseCall = () => {
    if (user?.sub === receiver?.cognitoId) {
      // This case when current user is receiver call
      handleCloseJitsiCalling(receiver?.cognitoId, caller?.cognitoId);
      return;
    } else {
      // Case current user is caller
      handlerCloseCallingForAll();
    }
  };

  // Handle on view
  useEffect(() => {
    if (jitsiSetting && jitsiSetting.getJitsiSetting) {
      const jitsiSettingValue = JSON.parse(jitsiSetting.getJitsiSetting.value);
      setJitsiDomain(jitsiSettingValue.serverName);
    }
  }, [jitsiSetting]);

  return (
    <Dialog open={isCalling && !!jitsiDomain} fullScreen onClose={() => handleCloseCall}>
      <DialogContent sx={{ p: 0 }}>
        <JitsiMeeting
          domain={jitsiDomain} // meet.knowhowcoach.eu
          roomName={jitsiRoomName}
          configOverwrite={{
            startWithAudioMuted: false,
            disableModeratorIndicator: true,
            startScreenSharing: false,
            enableEmailInStats: false,
          }}
          interfaceConfigOverwrite={{
            DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
          }}
          userInfo={{
            displayName: user?.displayName,
            email: user?.email,
          }}
          onApiReady={(externalApi) => {
            console.log('externalApi: ', externalApi);
            // here you can attach custom event listeners to the Jitsi Meet External API
            // you can also store it locally to execute commands
          }}
          onReadyToClose={() => {
            console.log('call back handle close');
            handleCloseCall();
          }}
          getIFrameRef={(iframeRef) => {
            iframeRef.style.height = '100%';
          }}
        />
      </DialogContent>
    </Dialog>
  );
}
