import React, { useEffect, useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Avatar, ListItemAvatar, MenuItem, Stack, Typography } from '@mui/material';
// utils
// _mock_
// hooks
import useLocales from '../../../hooks/useLocales';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
// @apollo
import { useQuery } from '@apollo/client';
// graphql
import { LIST_USERS } from '../../../graphql/queries';
// @types
import { UserData } from '../../../@types/user';
// hooks
import { userDisplayName } from '../../../hooks/useUser';
import useAuth from '../../../hooks/useAuth';
// utils
import createAvatar from '../../../utils/createAvatar';
import getUserAvatar from '../../../utils/getUserAvatar';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 64;

// ----------------------------------------------------------------------

export default function ContactsPopover() {
  const { translate } = useLocales();
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const { user } = useAuth();
  const navigate = useNavigate();
  const { loading, data } = useQuery(LIST_USERS);
  const [userList, setUserList] = useState<UserData[] | []>([]);
  const handleSetUser = (userList: any) => {
    setUserList(
      userList.map((el: UserData) => ({
        ...el,
        name: userDisplayName({
          email: el.email,
          firstName: el.firstName ?? '',
          lastName: el.lastName ?? '',
        }),
        jobTitle: el.jobTitle ?? '',
      }))
    );
  };
  useEffect(() => {
    if (data) {
      handleSetUser(data.listUsers);
    }
  }, [loading, data]);

  const handleViewProfile = (id: string) => {
    navigate(id !== user?.sub ? `${PATH_DASHBOARD.user.root}/${id}/personal` : '#');
    setOpen(null);
  };
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <Iconify icon={'eva:people-fill'} width={20} height={20} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 320,
          '& .MuiMenuItem-root': {
            px: 1.5,
            height: ITEM_HEIGHT,
            borderRadius: 0.75,
          },
        }}
      >
        <Typography variant="h6" sx={{ p: 1.5 }}>
          {translate('header.contacts')}{' '}
          <Typography component="span">({userList.length})</Typography>
        </Typography>

        <Scrollbar sx={{ height: ITEM_HEIGHT * 6 }}>
          {userList.map((contact) => (
            <MenuItem
              key={contact.id || contact.cognitoId}
              onClick={() => handleViewProfile(contact.id || contact.cognitoId)}
            >
              <ListItemAvatar sx={{ position: 'relative' }}>
                <Avatar
                  alt={contact.name}
                  src={getUserAvatar(contact)}
                  color={contact.avatarUrl ? 'default' : createAvatar(contact.name).color}
                  sx={{
                    borderStyle: 'solid',
                    borderColor: 'common.white',
                  }}
                >
                  {createAvatar(contact.name).name}
                </Avatar>
              </ListItemAvatar>

              <Stack>
                <Typography variant="subtitle2" color="inherit">
                  {contact.name}
                  {(contact.id || contact.cognitoId) === user?.sub && (
                    <Typography
                      variant="caption"
                      sx={{ fontStyle: 'italic', color: 'text.secondary' }}
                    >
                      &nbsp;(It's you)
                    </Typography>
                  )}
                </Typography>
                <Typography variant="caption" noWrap>
                  {contact.email}
                </Typography>
              </Stack>
            </MenuItem>
          ))}
        </Scrollbar>
      </MenuPopover>
    </>
  );
}
